@import '~antd/dist/antd.css';

.App {
  min-width: 400px;
  min-height: 100vh;
  color: #333;
}

.title {
  font-size: 14px;
  margin: 15px 0;
}

.ant-typography {
  margin-top: 0 !important;
}

.ant-table-wrapper {
  box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
}

.ant-table-thead > tr > th {
  background: #999;
  color: white;
}
