.header {
	font-size: 18px;
	padding: 0 20px;
	height: 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
	margin-bottom: 20px;
}

.logo {
	width: 230px;
}

.main {
	padding: 0 20px;
}

.cell {
	box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
	margin-bottom: 5px;
	height: 50px;
	display: flex;
	flex: 1;
	font-size: 14px;
}

.cell-title {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #999;
  color: white;
	width: 140px;
}

.cell-content {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 16px;
	padding: 0 10px;
	word-break: break-all;
}

.footer {
	flex: 1;
	padding: 20px;
	font-size: 12px;
	line-height: 16px;
	font-weight: bold;
	text-align: center;
}
